/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes/routes";
import footerRoutes from "footer.routes";

// Image
import bgImage from "assets/images/illustrations/illustration-reset.jpg";
import { logout } from "services/AuthService";
import { Divider } from "@mui/material";
import { Terms } from "pages/Terms/Terms";

function Profile() {
  const date = new Date().getFullYear();
  const handleLogOut = () => {
    logout();
  };
  const companyName = localStorage.getItem("company");
  const username = localStorage.getItem("username");
  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            route: "https://mtpindo.co.id",
            label: "Our Website",
            color: "info",
          }}
        />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${bgImage})` }}
          />
        </Grid>
        <Grid item xs={12} sm={10} md={7} lg={6} xl={4}>
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Profile
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                For further questions, including partnership opportunities, please email
                hello@creative-tim.com or contact using our contact form.
              </MKTypography>
              <MKBox width="100%">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <MKInput
                      variant="standard"
                      label="Username"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      value={username}
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKInput
                      type="email"
                      variant="standard"
                      label="Company / Personal Name"
                      InputLabelProps={{ shrink: true }}
                      value={companyName}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <Terms />
                </Grid>
              </MKBox>
            </MKBox>

            <MKBox p={3}>
              <Divider />
              <MKButton
                type="button"
                variant="gradient"
                color="info"
                onClick={handleLogOut}
                fullWidth
              >
                Sign Out
              </MKButton>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6} style={{ textAlign: "center" }}>
        <MKTypography variant="button" fontWeight="regular">
          All rights reserved. Copyright &copy; {date} MasterCool
        </MKTypography>
      </MKBox>
    </>
  );
}

export default Profile;
