import axios from "axios";
import { toast } from "react-toastify";

export const BaseAPIURL = "https://masterreport.mtpindo.co.id";
// export const BaseAPIURL = "https://masterreport.test";
export const ServerAPIURl = `${BaseAPIURL}/api/client`;

export function authHeader() {
  // return authorization header with jwt token
  const currentUser = localStorage.getItem("token");
  if (currentUser) {
    return {
      Authorization: `Bearer ${currentUser}`,
    };
  } else {
    return {};
  }
}

export const handleError = (error) => {
  if (error.response) {
    let message = "";
    if (error.response.status === 401) {
      message = "UnAuthorized";
      localStorage.removeItem("token");
      localStorage.removeItem("profileURL");
      localStorage.removeItem("authenticated");
      localStorage.removeItem("username");
      localStorage.removeItem("company");
      document.location.href = "/";
    } else if (error.response.status === 404) {
      message = "API Route is Missing or Undefined";
    } else if (error.response.status === 405) {
      message = "API Route Method Not Allowed";
    } else if (error.response.status === 422) {
      // Validation Message
    } else if (error.response.status >= 500) {
      message = "Server Error. Please Contact Administrator";
    }

    // Try to Use the Response Message
    if (
      Object.prototype.hasOwnProperty.call(error, "response") &&
      Object.prototype.hasOwnProperty.call(error, "data")
    ) {
      if (
        Object.prototype.hasOwnProperty.call(error, "message") &&
        error.response.data.message.length > 0
      ) {
        message = error.response.data.message;
        if (Object.prototype.hasOwnProperty.call(error, "errors") && error.response.data.errors) {
          Object.keys(error.response.data.errors).forEach((key) => {
            message = message.concat(error.response.data.errors[key]);
          });
        }
      }
    }
    toast.error(`Error ! ${message}`, {});
  } else if (error.request) {
    toast.error(`Error ! ${error.request}`, {});
  } else {
    toast.error(`Error ! ${error.message}`, {});
  }
};

export const login = (email, password) => {
  return axios
    .post(`${ServerAPIURl}/login`, {
      email,
      password,
    })
    .then((response) => {
      if (response.data.status === "success") {
        const isAuthenticated = true;
        localStorage.setItem("authenticated", isAuthenticated);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("company", response.data.company);
        localStorage.setItem("username", response.data.username);
        window.location.href = "/";
      } else {
        window.location.href = "/login";
      }
    })
    .catch((error) => {
      alert(`Error! ${JSON.stringify(error)}`);
      // window.location.href = "/login";
    });
};

export const logout = () => {
  localStorage.removeItem("authenticated");
  localStorage.clear();
  window.location.href = "/";
};
